import React, { useState } from 'react';
import { HeaderLink } from './HeaderLink';
import { ExpandIcon, ExpandableLabel } from '../style';

export const MobileExpandableItem = ({ label, links, handleClick }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  function toggleExpand() {
    setIsExpanded(!isExpanded);
  }

  return (
    <>
      <ExpandableLabel onClick={toggleExpand}>
        {label}
        <ExpandIcon isExpanded={isExpanded}>{'>'}</ExpandIcon>
      </ExpandableLabel>

      {isExpanded && links.map((linkProps, i) => (
        <HeaderLink
          {...linkProps}
          key={i}
          isSubLink
          handleClick={() => {
            toggleExpand();
            handleClick();
          }}
        />
      ))}
    </>
  );
};
