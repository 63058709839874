import styled, { css } from 'styled-components';

const baseButtonStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  text-decoration: none;
  border-radius: 4px;
  cursor: pointer;
`;

export const BaseButtonLinkStyles = styled.a`
  height: 80px;
  width: 243px;
  font-size: 18px;
  line-height: 32px;

  @media (max-width: 900px) {
    height: 64px;
    font-size: 16px;
  }

  @media (max-width: 767px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const buttonPrimaryStyles = css`
  ${baseButtonStyles};
  background: #1A5AFF;

  &:hover {
    background: #316BFF;
  }
`;

export const buttonSecondaryStyles = css`
  ${baseButtonStyles};
  border: 1px solid #3B486A;

  &:hover {
    border: 1px solid #606F95;
  
  }
`;

export const buttonTertiaryStyles = css`
  ${baseButtonStyles};
  border: 1px solid #1A5AFF;
  color: #1A5AFF;

  &:hover {
    color: #316BFF;
    border-color: #316BFF;
  }
`;
