import React from 'react';
import { IconBase } from './IconBase';

export function RedirectIcon(props) {
  return (
    <IconBase {...props}>
      <svg width="14" height="14" viewBox="0 0 14 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.5 2.25C2.4337 2.25 2.37011 2.27634 2.32322 2.32322C2.27634 2.37011 2.25 2.4337 2.25 2.5V9.5C2.25 9.5663 2.27634 9.62989 2.32322 9.67678C2.37011 9.72366 2.4337 9.75 2.5 9.75H9.5C9.5663 9.75 9.62989 9.72366 9.67678 9.67678C9.72366 9.62989 9.75 9.5663 9.75 9.5V8.75C9.75 8.33579 10.0858 8 10.5 8C10.9142 8 11.25 8.33579 11.25 8.75V9.5C11.25 9.96413 11.0656 10.4092 10.7374 10.7374C10.4092 11.0656 9.96413 11.25 9.5 11.25H2.5C2.03587 11.25 1.59075 11.0656 1.26256 10.7374C0.934374 10.4092 0.75 9.96413 0.75 9.5V2.5C0.75 2.03587 0.934374 1.59075 1.26256 1.26256C1.59075 0.934374 2.03587 0.75 2.5 0.75H3.25C3.66421 0.75 4 1.08579 4 1.5C4 1.91421 3.66421 2.25 3.25 2.25H2.5Z" />
        <path d="M6 1.5C6 1.08579 6.33579 0.75 6.75 0.75H10.5C10.9142 0.75 11.25 1.08579 11.25 1.5V5.25C11.25 5.66421 10.9142 6 10.5 6C10.0858 6 9.75 5.66421 9.75 5.25V3.31066L6.06066 7C5.76777 7.29289 5.29289 7.29289 5 7C4.70711 6.70711 4.70711 6.23223 5 5.93934L8.68934 2.25H6.75C6.33579 2.25 6 1.91421 6 1.5Z" />
      </svg>
    </IconBase>
  );
}
