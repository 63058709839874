import andreessenHorowitzLogo from 'images/adrian/logos/andreessenhorowitz.svg';
import blockTowerLogo from 'images/adrian/logos/blocktower.svg';
import foundersFundLogo from 'images/adrian/logos/founders-fund.svg';

export const trustedByCompany = [
  {
    logo: andreessenHorowitzLogo,
    name: 'andreessenHorowitzLogo',
  },
  {
    logo: blockTowerLogo,
    name: 'blockTowerLogo',
  },
  {
    logo: foundersFundLogo,
    name: 'foundersFundLogo',
  },
];
