import React from 'react';
import styled from 'styled-components';
import { socials } from './socials';

export const FooterSocial = () => (
  <List>
    {socials.map(({ icon, link }) => <ListItem key={link} icon={icon} link={link} />)}
  </List>
);

const ListItem = ({ icon, link }) => {
  const Icon = icon;

  return (
    <Item>
      <Link href={link} target="_blank" rel="noreferrer noopener">
        <Icon color="fillColor" />
      </Link>
    </Item>
  );
};

const List = styled.ul`
  display: flex;
  align-items: center;
  margin-left: auto;

  @media (max-width: 767px) {
    justify-content: center;
    order: 1;
    width: 100%;
    margin-top: 24px;
  }
`;

const Item = styled.li`
  margin-left: 8px;

  svg {
    fill:#9FAAC4;;
  }

  @media (max-width: 767px) {
    margin: 0;

    & + & {
      margin-left: 4px;
    }
  }
`;

const Link = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;

  &:hover {
    svg {
      fill: #1A5AFF;
    }
  }
`;
