import { ChainId } from './chain';

export const ADDRESSES = {
  multicall: {
    [ChainId.Mainnet]: '0xeefba1e63905ef1d7acba5a8513c70307c1ce441',
    [ChainId.Ropsten]: '0x53c43764255c17bd724f74c4ef150724ac50a3ed',
    [ChainId.Ganache]: '0xB547c0f2823917bFE26e9B8513AEba174c15a41e',
  },
  tusd: {
    [ChainId.Mainnet]: '0x0000000000085d4780B73119b644AE5ecd22b376',
    [ChainId.Ropsten]: '0x1cB0906955623920c86A3963593a02a405Bb97fC',
    [ChainId.Ganache]: '0x0387B70afee866765b79e37E844E2ebc53356953',
  },
  trustToken: {
    [ChainId.Mainnet]: '0x4C19596f5aAfF459fA38B0f7eD92F11AE6543784',
    [ChainId.Ropsten]: '0x12b2f909d9ea91c86dc7fbba272d8abbcddfd72c',
    [ChainId.Ganache]: '0x2Db4776bD99fD1b82535F8893dAe9a3290FA851f',
  },
  pool: {
    [ChainId.Mainnet]: '0xa1e72267084192Db7387c8CC1328fadE470e4149',
    [ChainId.Ropsten]: '0xd943D9CB0904bB2a057f2e6AdE738daCA3e5d405',
    [ChainId.Ganache]: '0xBcF86fcfB1025AA03C24042D8e3b63d8a2Fe7751',
  },
  usdcPool: {
    [ChainId.Mainnet]: '0xA991356d261fbaF194463aF6DF8f0464F8f1c742',
    [ChainId.Ropsten]: '0xbe3d5088CE089eDC17987D9Ee4852FA5dF458995',
  },
  usdc: {
    [ChainId.Mainnet]: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    [ChainId.Ropsten]: '0x671A5708E1b038725fceEF51dFE9453295788125',
  },
  usdtPool: {
    [ChainId.Mainnet]: '0x6002b1dcB26E7B1AA797A17551C6F487923299d7',
    [ChainId.Ropsten]: '0xc86342ea7C71Afae131bD6E279530C5cC592a4c6',
  },
  usdt: {
    [ChainId.Mainnet]: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    [ChainId.Ropsten]: '0x110a13FC3efE6A245B50102D2d79B3E76125Ae83',
  },
  busdPool: {
    [ChainId.Mainnet]: '0x1Ed460D149D48FA7d91703bf4890F97220C09437',
    [ChainId.Ropsten]: '0xb400E88f5c015d2fbb3D04b517B0dcfc950D974D',
  },
  tusdPool: {
    [ChainId.Mainnet]: '0x97cE06c3e3D027715b2d6C22e67D5096000072E5',
    [ChainId.Ropsten]: '0x2b3E3A205042893431a18F212598f815F9320937',
  },
  erc20: {
  },
  pool2: {
    [ChainId.Mainnet]: '0x27E15d51AfB899104b69fF5969eaAD29453947b9',
  },
  poolFactory: {
    [ChainId.Mainnet]: '0x1391D9223E08845e536157995085fE0Cef8Bd393',
    [ChainId.Ropsten]: '0x6b742eB6bd54537721Ee2fA41229745220A7ce55',
  },
  lender: {
    [ChainId.Mainnet]: '0x16d02Dc67EB237C387023339356b25d1D54b0922',
    [ChainId.Ropsten]: '0xb1f283F554995F666bD3238F229ADf0aF7d54fC4',
    [ChainId.Ganache]: '0x5203B97C72Fe6f28dD4Dc720eC0207C81d662740',
  },
  lender2: {
    [ChainId.Mainnet]: '0xa606dd423dF7dFb65Efe14ab66f5fDEBf62FF583',
    [ChainId.Ropsten]: '0xc16eEed3cC648225105B199324E0e2cd3ADB86db',
  },
  curveLiquidityToken: {
    [ChainId.Mainnet]: '0xdf5e0e81dff6faf3a7e52ba697820c5e32d806a8',
    [ChainId.Ropsten]: '0x499d6727B38011A214bbCAdd9C37B00C1A0F0909',
    [ChainId.Ganache]: '0x499d6727B38011A214bbCAdd9C37B00C1A0F0909',
  },
  loanFactory: {
    [ChainId.Mainnet]: '0x4ACE6dE67E9a9EDFf5c2d0a584390Fb5394119e7',
    [ChainId.Ropsten]: '0xF2F9604c1eC79833b36bC8f494828C87C0983c96',
    [ChainId.Ganache]: '0x51bbf22Ac672065C2235F6bbd7EE6B732baAf4e9',
  },
  loanFactory2: {
    [ChainId.Mainnet]: '0x69d844fB5928d0e7Bc530cC6325A88e53d6685BC',
    [ChainId.Ropsten]: '0x5f31A8BdA2d9C126D8b95892f05f146361a6d9B7',
  },
  crv: {
    [ChainId.Mainnet]: '0xD533a949740bb3306d119CC777fa900bA034cd52',
    [ChainId.Ropsten]: '0x490f856a7167a51410dbcd852eb8fc15dbe14624',
    [ChainId.Ganache]: '',
  },
  balFarm: {
    [ChainId.Mainnet]: '0x493945574d9D41c1b553a3E5b71090eFc99Bf929',
    [ChainId.Ropsten]: '0x31Bf9464BEf50E927A5d7f4A8336Dd32c2556dd2',
  },
  ethFarm: {
    [ChainId.Mainnet]: '0xED45Cf4895C110f464cE857eBE5f270949eC2ff4',
    [ChainId.Ropsten]: '0x2c2f559b45Cc908b277024980f957B2e1abED662',
  },
  truePoolFarm: {
    [ChainId.Mainnet]: '0x8FD832757F58F71BAC53196270A4a55c8E1a29D9',
    [ChainId.Ropsten]: '0xbc10fb92137fE89494Ebe2a5D29DeCC01CDb608C',
    [ChainId.Ganache]: '',
  },
  tfUSDCFarm: {
    [ChainId.Mainnet]: '0x6b6A4EABa8bA12765dF51a859C0Fa75894817f5a',
    [ChainId.Ropsten]: '0x3b375F0426cc592D2991Dc43B3b90F691cD900Ff',
    [ChainId.Ganache]: '',
  },
  tfUSDTFarm: {
    [ChainId.Mainnet]: '0xC06E16D2Fbe2b9Fe088a615f23cb20898745Dc6D',
    [ChainId.Ropsten]: '0x3b375F0426cc592D2991Dc43B3b90F691cD900Ff',
    [ChainId.Ganache]: '',
  },
  tusdFarm: {
    [ChainId.Mainnet]: '0xf8F14Fbb93fa0cEFe35Acf7e004fD4Ef92d8315a',
    [ChainId.Ropsten]: '0xCfF41229684AF4A6dc3777D9570A2C605a53Cb4F',
  },
  ratingAgency: {
    [ChainId.Mainnet]: '0x43A4F930F2cC35948d3a6dcd47CD0E50761f9B88',
    [ChainId.Ropsten]: '0xBaf182DDCBE010F764bcC1e1FE25a71Fe6FdA6F7',
    [ChainId.Ganache]: '',
  },
  ethTruUniswapPair: {
    [ChainId.Mainnet]: '0xec6a6b7db761a5c9910ba8fcab98116d384b1b85',
    [ChainId.Ropsten]: '0xe6cef51e93bb40a99de03aa9396b13569c923ac7',
  },
  tusdTrueFiLPUniswapPair: {
    [ChainId.Mainnet]: '0xAC08f19b13548d55294BCEfCf751d81EA65845d5',
    [ChainId.Ropsten]: '0x5cd5213c1af1091f95d024a4ad04c0f871db2bd7',
  },
  balTruBalancerPair: {
    [ChainId.Mainnet]: '0x432081ef9aa1b8503f8c7be37e4bb158a0543da9',
    [ChainId.Ropsten]: '0x103B94b04800908d25F68F55f6f30e21CCdaC863',
  },
  wrappedEth: {
    [ChainId.Mainnet]: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    [ChainId.Ropsten]: '0xb603cea165119701b58d56d10d2060fbfb3efad8',
  },
  uniswapRouter: {
    [ChainId.Mainnet]: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
    [ChainId.Ropsten]: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
  },
  dai: {
    [ChainId.Mainnet]: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
  },
  loanToken: {
  },
  loanToken2: {
  },
  curveGauge: {
    [ChainId.Mainnet]: '0xFA712EE4788C042e2B7BB55E6cb8ec569C4530c1',
  },
  crvMinter: {
    [ChainId.Mainnet]: '0xd061D61a4d941c39E5453435B6345Dc261C2fcE0',
  },
  ethTruUniswapPairDistributer: {
    [ChainId.Mainnet]: '0x8EFF7d12118Fd599772D6448CDAd11D5fb2568e0',
    [ChainId.Ropsten]: '0x0774EBa5e750A01305E0e7c64f399743849A04F7',
  },
  tusdTrueFiLPUniswapPairDistributer: {
    [ChainId.Mainnet]: '0xCc527F4f8c76dB1EBA217d001cCc6f8bD9e0D86E',
    [ChainId.Ropsten]: '0x9cFE4F5EcbD6D161c10aA8aF4644be4113423c06',
  },
  balTruBalancerPairDistributer: {
    [ChainId.Mainnet]: '0x4A90472cD446A1686FB6d7e86aCE50AF03e71073',
    [ChainId.Ropsten]: '0x22216f065Cdf9247eee8ADefFFA47DB9327b76e8',
  },
  trueFiLPDistributer: {
    [ChainId.Mainnet]: '0xfB8d918428373f766B352564b70d1DcC1e3b6383',
    [ChainId.Ropsten]: '0xA7267e268F1282cA1589Ae59A4043c09dbe350e5',
  },
  tfUSDCFarmDistributer: {
    [ChainId.Mainnet]: '0x2185b903867212539f6b744d08fa6fd26c4a9310',
    [ChainId.Ropsten]: '0xB3fa96579cCc4769DeEa5B08fCFbC56A34996480',
  },
  tfUSDTFarmDistributer: {
    [ChainId.Mainnet]: '0xdEc91F8E7C30CEeCb008C82E7f0847aD3Dec98c4',
    [ChainId.Ropsten]: '0xB3fa96579cCc4769DeEa5B08fCFbC56A34996480',
  },
  ratingAgencyV2: {
    [ChainId.Mainnet]: '0x05461334340568075bE35438b221A3a0D261Fb6b',
    [ChainId.Ropsten]: '0x6854E91dEB5D7Ec35b556711c5ED6c702C67eefC',
  },
  stkTru: {
    [ChainId.Mainnet]: '0x23696914Ca9737466D8553a2d619948f548Ee424',
    [ChainId.Ropsten]: '0xccf081f684b3481503080ca4240f76d8381a7ef5',
  },
  stkTruDistributor: {
    [ChainId.Mainnet]: '0xecfD4F2C07EABdb7b592308732B59713728A957F',
    [ChainId.Ropsten]: '0x591F55835e2514a58eA430B253db783115dad789',
  },
  stkTruLiquidator: {
    [ChainId.Mainnet]: '0x76dd4921C99AC6b61b3a98f9fa6f181cA6D70c77',
    [ChainId.Ropsten]: '0xAab6eC654d502a4efF6dcb85832C690858A691d5',
  },
  liquidator2: {
    [ChainId.Mainnet]: '0x7aC899754Dd042024bb168fd5c9a07420F444Bdf',
  },
  governor: {
    [ChainId.Mainnet]: '0x0236c16f06aAFdbea5b5EDC8C326A479DB090eB2',
    [ChainId.Ropsten]: '0xb6ece4fb723555051888e444e26acb9b149e4685',
  },
  creditOracle: {
    [ChainId.Mainnet]: '0x73581551665680696946f568259977Da02e8712A',
    [ChainId.Ropsten]: '0x9ff6ca759631E658444Ba85409a283f55C49bb93',
  },
  safu: {
    [ChainId.Mainnet]: '0x96078ee7aDf86328f61b54b72127121683B3c94E',
  },
};
