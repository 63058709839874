import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { buttonPrimaryStyles } from '../Button/Button';

export const HeaderWrapper = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  padding: 16px 0;
  width: 100%;
  background: #0b0e14;
  z-index: 99;
`;

export const Container = styled.div`
  max-width: 1182px;
  width: 100%;
  padding: 0 16px;
  margin: 0 auto;
`;

export const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .show {
    @media (max-width: 767px) {
      flex-direction: column;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    flex-flow: nowrap;
  }
`;

export const LogoLink = styled(Link)`
  margin-right: 32px;
`;

export const MobileMenuButton = styled.button`
  display: none;
  width: 40px;
  height: 40px;
  background: transparent;
  border: none;

  @media (max-width: 767px) {
    display: block;
  }
`;

export const MobileMenuWrapper = styled.div`
  @media (min-width: 768px) {
    display: none;
  }

  @media screen and (max-width: 767px) {
    height: ${({ isDisplayed, menuOffset }) => (isDisplayed ? `calc(100vh - ${menuOffset || 75}px)` : '0px')};
    overflow-y: scroll;
    padding-bottom: ${({ isDisplayed }) => (isDisplayed ? '40px' : '0px')};
  }
`;

export const NavWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 767px) {
    display: none;
  }
`;

export const NavBar = styled.ul`
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  column-gap: 40px;
  row-gap: 40px;

  @media (max-width: 964px) {
    column-gap: 24px;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    margin: 48px 0px;
  }
`;

export const NavLinkItem = styled.li`
  ${({ isSubLink }) => (
    isSubLink ? 'opacity: .75;' : ''
  )}
  @media (max-width: 767px) {
    display: block;
    width: 100%;
    text-align: center;
  }
`;

const navLinkStyles = css`
  display: block;
  text-decoration: none;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #e7eaf3;
  cursor: pointer;

  &:hover {
    color: #1a5aff;
  }

  @media (max-width: 767px) {
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
  }
`;

export const ExpandableLabel = styled.div`
  ${navLinkStyles}
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NavLink = styled.a`
  ${navLinkStyles}
`;

export const NavLinkInternal = styled(Link)`
  ${navLinkStyles}
`;

export const EarnButtonWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

export const EarnButton = styled.a`
  ${buttonPrimaryStyles}
  font-size: 16px;
  line-height: 24px;
  padding: 10px 13px;
  margin-left: 24px;

  @media (max-width: 767px) {
    width: 100%;
    height: 56px;
    margin: 8px 0 0;
  }
`;

export const DropdownLabel = styled.span`
  ${navLinkStyles};
`;

export const DropdownList = styled.nav`
  display: flex;
  position: absolute;
  visibility: hidden;
  top: calc(100% + 8px);
  flex-direction: column;
  padding: 8px 0px;
  min-width: 148px;
  border-radius: 2px;
  background-color: #1D273B;
`;

export const DropdownContainer = styled.div`
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 100%;
    height: 8px;
    width: 100%;
  }

  &:hover,
  &:focus,
  &:focus-visible {
    ${DropdownList} {
      visibility: visible;
    }
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

export const DropdownLink = styled.a`
  display: flex;
  align-items: center;
  flex-wrap: none;
  padding: 0 12px;
  color: #ffffff;
  text-decoration: none;
  height: 32px;

  & + & {
    margin-top: 4px;
  }

  &:hover,
  &:focus-visible {
    color: #1a5aff;
  }
`;

export const DropdownLinkIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
`;

export const DropdownLinkText = styled.div`
  margin-left: 4px;
  white-space: nowrap;
`;

export const ExpandIcon = styled.div`
  margin-left: 16px;
  padding-bottom: 2px;
  opacity: .75;
  transition: transform .2s;

  ${({ isExpanded }) => isExpanded && `
    transform: rotate(90deg);
  `}
`;
