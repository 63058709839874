import { TwitterIcon } from '../../icons/Twitter';
import { FacebookIcon } from '../../icons/Facebook';
import { TelegramIcon } from '../../icons/Telegram';
import { MailIcon } from '../../icons/Mail';
import { MediumIcon } from '../../icons/Medium';
import { DiscordIcon } from '../../icons/Discord';
import { YoutubeIcon } from '../../icons/YouTube';
import { LinkedinIcon } from '../../icons/Linkedin';

const discord = {
  icon: DiscordIcon,
  link: 'https://discord.gg/Yx7J4jZ',
  label: 'Discord',
};
const twitter = {
  icon: TwitterIcon,
  link: 'https://twitter.com/TrueFiDAO',
  label: 'Twitter',
};
const telegram = {
  icon: TelegramIcon,
  link: 'https://t.me/jointruefi',
  label: 'Telegram',
};
const medium = {
  icon: MediumIcon,
  link: 'https://blog.trusttoken.com/',
  label: 'Medium',
};
const youtube = {
  icon: YoutubeIcon,
  link: 'https://www.youtube.com/channel/UCePpU7NPWENI6rdmFb7HALA',
  label: 'Youtube',
};
const substack = {
  icon: MailIcon,
  link: 'https://truefi.substack.com/',
  label: 'Newsletter',
};

const socials = [
  discord,
  twitter,
  substack,
  telegram,
  medium,
  youtube,
];

const communitySocials = [discord, telegram, twitter, substack];

export { socials, communitySocials };
