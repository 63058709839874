import { communitySocials } from '../Footer/socials';

export const HEADER_LINKS = [
  { label: 'Docs', link: 'https://docs.truefi.io/ ', isExternal: true },
];

export const GOVERNANCE_LINKS = [
  {
    link: 'https://bit.ly/chattruefi',
    label: 'Discuss in Discord',
    isExternal: true,
  },
  {
    link: 'https://forum.truefi.io/',
    label: 'Propose to Forum',
    isExternal: true,
  },
  {
    link: 'https://snapshot.org/#/truefi-dao.eth/',
    label: 'Signal on Snapshot',
    isExternal: true,
  },
  {
    link: 'https://www.tally.xyz/governance/eip155:1:0x585CcA060422ef1779Fb0Dd710A49e7C49A823C9',
    label: 'Vote On-Chain',
    isExternal: true,
  },
  {
    link: 'https://www.tally.xyz/governance/eip155:1:0x585CcA060422ef1779Fb0Dd710A49e7C49A823C9',
    label: 'Delegate on Tally',
    isExternal: true,
  },
];

export const HEADER_DROPDOWNS = [
  { label: 'Community', links: communitySocials },
  { label: 'Governance', links: GOVERNANCE_LINKS },
];
