import React from 'react';
import styled from 'styled-components';
import { TokenInfoSection } from '../LandingPage/TokenInfoSection/TokenInfoSection';
import arrowIcon from '../../images/icons/arrow-right-blue.svg';
import documentIcon from '../../images/icons/document.svg';
import technologyIcon from '../../images/icons/technology.svg';
import { SectionTitleDark } from '../Text/SectionTitle';
import { Container } from '../layout/Container';

export const LearnSection = ({ tokenInfo }) => (
  <View>
    <Container>
      <Content>
        {tokenInfo && <TokenInfoSection />}
        <StyledSectionTitleDark data-aos="fade-up">Learn more about TrueFi</StyledSectionTitleDark>
        <List>
          <LinkItem
            icon={<img src={documentIcon} alt="document" />}
            label="Docs"
            text="Learn how TrueFi infrastructure powers on-chain credit."
            href="https://docs.truefi.io/faq/"
            target="_blank"
          />
          <LinkItem
            icon={<img src={technologyIcon} alt="technology" />}
            label="Technology"
            text="Review TrueFi's codebase, protocol specs, and audits on GitHub ."
            href="https://github.com/trusttoken/truefi-spec"
            target="_blank"
          />
        </List>
      </Content>
    </Container>
  </View>
);

const LinkItem = ({ icon, label, text, href, target }) => (
  <ListItem data-aos="fade-up">
    <Link href={href} target={target}>
      <Row>
        {icon}
        <Label>{label}</Label>
      </Row>
      <Text>{text}</Text>
      <ArrowIcon src={arrowIcon} alt="arrow right" />
    </Link>
  </ListItem>
);

const View = styled.section`
  background: #fff;
`;

const Content = styled.div`
  position: relative;
  padding: 24px 0 0;
`;

const ArrowIcon = styled.img`
  margin-left: 64px;
  transition: all 0.2s;

  @media (max-width: 767px) {
    position: absolute;
    top: 16px;
    right: 16px;
  }
`;

const ListItem = styled.div`
  & + & {
    margin-top: 15px;
  }
`;

const Link = styled.a`
  display: flex;
  align-items: center;
  padding: 28px 56px;
  background: #FFFFFF;
  box-shadow: 0px 24px 60px rgba(2, 10, 33, 0.04);
  text-decoration: none;

  @media (max-width: 767px) {
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
  }

  &:hover ${ArrowIcon} {
    transform: translateX(12px);
  }
`;

const List = styled.div`
  padding: 40px 0 104px;

  @media (max-width: 600px) {
    padding: 40px 0 80px;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  max-width: 264px;
  width: 100%;
  margin-right: 64px;

  img {
    width: 48px;
    height: 48px;
    margin-right: 16px;
  }

  @media (max-width: 900px) {
    max-width: 150px;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    max-width: initial;
    margin: 0 0 16px;

    img {
      margin: 0 0 16px;
    }
  }
`;

const Label = styled.p`
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  color: #000000;

  @media (max-width: 900px) {
    font-size: 18px;
  }

  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 120%;
  }
`;

const Text = styled.p`
  max-width: 492px;
  margin-right: auto;
  font-size: 18px;
  line-height: 28px;
  color: #6C7893;

  @media (max-width: 900px) {
    font-size: 14px;
    line-height: 20px;
  }

  @media (max-width: 600px) {
    font-size: 12px;
  }
`;

const StyledSectionTitleDark = styled(SectionTitleDark)`
  @media (max-width: 900px) {
    text-align: center;
  }
`;
