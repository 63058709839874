import React from 'react';
import styled from 'styled-components';
import tokenImage from '../../../images/illustrations/token.svg';

export const TokenInfoSection = () => (
  <View data-aos="fade-up">
    <Row>
      <TextContent>
        <Title>TRU, the TrueFi governance token</Title>
        <Image src={tokenImage} alt="token" />
        <Text>TRU governs the protocol and is used to approve new managers and borrowers.</Text>
      </TextContent>
    </Row>
    <Footer>
      <div>
        <Label>Learn more:</Label>
        <List>
          <ListItem href="https://docs.truefi.io/faq/">Docs</ListItem>
          <ListItem href="https://etherscan.io/token/0x4c19596f5aaff459fa38b0f7ed92f11ae6543784">Etherscan</ListItem>
          <ListItem href="https://www.coingecko.com/en/coins/truefi">Coingecko</ListItem>
          <ListItem href="https://coinmarketcap.com/currencies/truefi-token">CoinMarketCap</ListItem>
        </List>
      </div>
      <Divider />
      <div>
        <Label>Markets:</Label>
        <List>
          <ListItem href="https://www.coinbase.com/price/truefi-token">Coinbase</ListItem>
          <ListItem href="https://www.binance.com/en/price/truefi-token">Binance</ListItem>
          <ListItem href="https://app.sushi.com/swap?inputCurrency=0x4c19596f5aaff459fa38b0f7ed92f11ae6543784&outputCurrency=0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2">Sushiswap</ListItem>
        </List>
      </div>
    </Footer>
  </View>
);

const ListItem = ({ href, children }) => (
  <Item>
    <Link
      href={href}
      target="_blank"
      rel="noreferrer noopener"
    >
      {children}
    </Link>
  </Item>
);

const View = styled.div`
  width: 100%;
  margin-bottom: 32px;
  padding: 72px 108px 56px 56px;
  background: #fff;
  box-shadow: 0px 24px 60px rgba(2, 10, 33, 0.04);

  @media (max-width: 990px) {
    padding: 48px 40px 40px;
  }

  @media (max-width: 767px) {
    padding: 24px;
  }
`;

const TextContent = styled.div`
  max-width: 462px;

  @media (max-width: 990px) {
    max-width: 55%;
  }

  @media (max-width: 767px) {
    max-width: initial;
  }
`;

const Title = styled.h2`
  margin-bottom: 24px;
  font-weight: 600;
  font-weight: 400;
  font-size: 28px;
  line-height: 40px;
  color: #0B0E14;

  @media (max-width: 990px) {
    margin-bottom: 16px;
    font-size: 24px;
    line-height: 120%;
  }

  @media (max-width: 600px) {
    font-size: 18px;
  }
`;

const Text = styled.p`
  font-size: 18px;
  line-height: 28px;
  color: #6C7893;

  @media (max-width: 990px) {
    font-size: 14px;
    line-height: 20px;
  }

  @media (max-width: 767px) {
    margin-top: 16px;
  }

  @media (max-width: 600px) {
    font-size: 12px;
    line-height: 20px;
  }
`;

const Image = styled.img`
  display: block;
  position: absolute;
  top: -32px;
  right: 0;
  max-width: 248px;
  max-height: 186px;

  @media (max-width: 990px) {
    top: -32px;
    max-width: 204px;
    margin: 0 auto;
  }

  @media (max-width: 767px) {
    position: static;
  }
`;

const Row = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 68px;

  @media (max-width: 990px) {
    margin-bottom: 48px;
  }

  @media (max-width: 767px) {
    margin-bottom: 16px;
  }
  `;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const Label = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #000000;

  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 120%;
  }
`;

const List = styled.ul`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
`;

const Item = styled.li`
  margin-bottom: 8px;

  &:not(:last-child) {
    margin-right: 32px;
  }
`;

const Link = styled.a`
  font-size: 16px;
  line-height: 24px;
  color: #1A5AFF;
  text-decoration: none;

  @media (max-width: 600px) {
    font-size: 12px;
    line-height: 20px;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const Divider = styled.hr`
  height: 64px;
  margin: 0 48px;
  border: none;
  border-left: 1px solid #D0D6E6;

  @media (max-width: 990px) {
    height: 88px;
  }

  @media (max-width: 767px) {
    height: 1px;
    width: 100%;
    margin: 8px 0 16px;
    border: none;
    border-top: 1px solid #D0D6E6;
  }
`;
