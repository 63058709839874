import { useEffect, useState } from 'react';

export function useResponsiveHelpers() {
  const [windowWidth, setWindowWidth] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);
  const [isMobileWidth, setIsMobileWidth] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };
    // initialize resize listener
    window.addEventListener('resize', handleResize);
    handleResize();
    // cleanup listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // watch width changes, update breakpoint helpers
  useEffect(setBreakpointHelpers, [windowWidth]);

  function setBreakpointHelpers() {
    if (windowWidth < 769 && !isMobileWidth) {
      setIsMobileWidth(true);
    }
    if (windowWidth > 768 && isMobileWidth) {
      setIsMobileWidth(false);
    }
  }

  return {
    windowWidth,
    windowHeight,
    isMobileWidth,
  };
}
