import styled from 'styled-components';
import { SectionTitleLight } from 'components/Text/SectionTitle';

export const Title = styled(SectionTitleLight)`
  max-width: 548px;

  @media (max-width: 900px) {
    max-width: initial;
    margin-bottom: 40px;
  }
`;

export const ButtonsRow = styled.div`
  @media (max-width: 900px) {
    display: flex;
    align-items: center;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const Button = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 270px;
  height: 80px;
  font-size: 18px;
  line-height: 32px;
  color: #1A5AFF;
  background: #fff;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: #0749F3;
  }

  @media (max-width: 900px) {
    width: 250px;
    height: 64px;
    font-size: 16px;
    line-height: 24px;
  }

  @media (max-width: 600px) {
    max-width: 312px;
    width: 100%;
    height: 56px;
    font-size: 14px;
    line-height: 32px;
  }
`;

export const LinkText = styled.p`
  margin-top: 24px;
  text-align: center;
  font-size: 18px;
  line-height: 32px;
  color: #D0D6E6;

  @media (max-width: 900px) {
    margin: 0 0 0 24px;
    font-size: 16px;
    line-height: 24px;
  }

  @media (max-width: 600px) {
    margin: 10px 0 0;
    font-size: 14px;
    line-height: 40px;
  }
`;

export const Link = styled.a`
  font-size: inherit;
  line-height: inherit;
  text-align: center;
  color: #F8F9FB;
`;
