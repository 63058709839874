import React from 'react';
import styled from 'styled-components';
import { FooterSocial } from './FooterSocial';

export const FooterBottom = () => (
  <Row>
    <FooterSocial/>
  </Row>
);

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  padding-top: 22px;
  border-top: 1px solid #E7EAF3;

  @media (max-width: 767px) {
    flex-wrap: wrap;
  }
`;
