import { ADDRESSES } from 'constants/addresses';
import { ChainId } from 'constants/chain';

import truefiLogo from 'images/icons/trulogo.svg';
import stkTruLogo from 'images/icons/stktru.svg';
import tfUsdcLogo from 'images/icons/tfUSDC.svg';
import tfUsdtLogo from 'images/icons/tfUSDT.svg';
import tfTusdLogo from 'images/icons/tftusdlogo.svg';
import tfBusdLogo from 'images/icons/tfbusdlogo.svg';

const { Mainnet } = ChainId;

export const tokens = [
  { name: 'Truefi', symbol: 'TRU', address: ADDRESSES.trustToken[Mainnet], logo: truefiLogo, decimals: 8 },
  { name: 'stkTRU', symbol: 'stkTRU', address: ADDRESSES.stkTru[Mainnet], logo: stkTruLogo, decimals: 8 },
  { name: 'tfUSDC', symbol: 'tfUSDC', address: ADDRESSES.usdcPool[Mainnet], logo: tfUsdcLogo, decimals: 6 },
  { name: 'tfUSDT', symbol: 'tfUSDT', address: ADDRESSES.usdtPool[Mainnet], logo: tfUsdtLogo, decimals: 6 },
  { name: 'tfTUSD', symbol: 'tfTUSD', address: ADDRESSES.tusdPool[Mainnet], logo: tfTusdLogo, decimals: 18 },
  { name: 'tfBUSD', symbol: 'tfBUSD', address: ADDRESSES.busdPool[Mainnet], logo: tfBusdLogo, decimals: 18 },
];
