import { tokens } from 'constants/tokens';
import React from 'react';
import styled from 'styled-components';

import { FooterBottom } from './FooterBottom';
import { Token } from './Token';
import { Container } from './../layout/Container';

export const Footer = () => {
  const metamaskConnected = typeof window !== 'undefined' && window.ethereum && window.ethereum.isMetaMask;
  return (
    <View>
      <Container>
        <Row>
          <RowItem>
            <Label>Tokens:</Label>
            <TokensList>
              {tokens.map(token => (
                <TokenItem key={token.name}>
                  <Token
                    displayWalletImport={metamaskConnected}
                    token={token}
                  />
                </TokenItem>
              ))}
            </TokensList>
          </RowItem>
          <RowItem>
            <Label>Find us on:</Label>
            <List>
              <ListItem href="https://coinmarketcap.com/currencies/truefi-token">CoinMarketCap</ListItem>
              <ListItem href="https://www.coingecko.com/en/coins/truefi">CoinGecko</ListItem>
              <ListItem href="https://defillama.com/protocol/truefi">DeFi Llama</ListItem>
              <ListItem href="https://dune.com/truefi">Dune Analytics</ListItem>
            </List>
          </RowItem>
          <RowItem>
            <Label>Markets:</Label>
            <List>
              <ListItem href="https://www.coinbase.com/price/truefi-token">Coinbase</ListItem>
              <ListItem href="https://app.sushi.com/swap?inputCurrency=0x4c19596f5aaff459fa38b0f7ed92f11ae6543784&outputCurrency=0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2">Sushi ETH/TRU</ListItem>
              <ListItem href="https://v2.info.uniswap.org/pair/0xec6a6b7db761a5c9910ba8fcab98116d384b1b85">Uniswap ETH/TRU</ListItem>
              <ListItem href="https://www.binance.com/en/trade/TRU_USDT?type=spot">Binance</ListItem>
            </List>
          </RowItem>
          <RowItem>
            <Label>Useful links:</Label>
            <UsefulLinks>
              <ListItem href="https://app.truefi.io/privacy-policy">Privacy Policy</ListItem>
              <ListItem href="https://app.truefi.io/terms-of-use">Terms of Use</ListItem>
              <ListItem href="https://forum.truefi.io">Forum</ListItem>
              <ListItem href="https://snapshot.org/#/truefi-dao.eth/">Snapshot</ListItem>
              <ListItem href="https://github.com/trusttoken/audits/tree/master/TrueFi">Code Audits</ListItem>
              <ListItem href="https://merch.truefi.io/">TrueFi Merchandise</ListItem>
              <ListItem href="https://www.figma.com/proto/4U4UFA6Hbdx4OXF3wQJm1B/TrustToken-Brand-Resources-TrustToken%2FTrueFi?page-id=0%3A1&node-id=1953%3A1090&viewport=241%2C48%2C0.15&scaling=min-zoom&starting-point-node-id=1953%3A1090&hide-ui=1">TrueFi Brand Kit</ListItem>
            </UsefulLinks>
          </RowItem>
        </Row>
        <FooterBottom/>

        <RewardsDisclosure>
          * Disclaimer: The information contained in this website, including the historical average APR, are intended for informational purposes only. Some of the information may be dated
          and may not reflect the most current information or lending rates. Lending rates are based on the creditworthiness of individual borrowers and are subject to change.
        </RewardsDisclosure>
      </Container>
    </View>
  );
};

const ListItem = ({ href, children }) => (
  <Item>
    <Link href={href} target="_blank" rel="noreferrer">{children}</Link>
  </Item>
);

const View = styled.footer`
  padding: 32px 0 24px;
  background: #fff;

  @media (max-width: 767px) {
    padding: 16px 0;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 767px) {
    flex-wrap: wrap;
  }
`;

const RowItem = styled.div`
  @media (max-width: 767px) {
    width: 50%;

    &:first-child {
      width: 100%;
      margin-bottom: 24px;
    }

    &:last-child {
      width: 100%;
    }
  }
`;

const Label = styled.p`
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #9FAAC4;
`;

const List = styled.ul`
  max-height: 168px;

  @media (max-width: 767px) {
    width: 50%;
    max-height: initial;
  }
`;

const UsefulLinks = styled(List)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 220px;

  @media (max-width: 767px) {
    max-height: 175px;
    width: 100%;
  }
`;

const Item = styled.li`
  width: 110px;
  margin: 0 0 8px 0;
`;

const Link = styled.a`
  font-size: 12px;
  line-height: 18px;
  color: #60697C;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: #1A5AFF;
  }
`;

const TokensList = styled.ul`
  max-height: 120px;
  column-count: 2;
  max-width: 324px;

  @media (max-width: 767px) {
    margin-top: 24px;
  }
`;

const TokenItem = styled.li`
  margin-right: 24px;
  margin-bottom: 20px;

  @media (max-width: 767px) {
    width: auto;
  }
`;

const RewardsDisclosure = styled.div`
  margin-top: 20px;
  color: #9FAAC4;
  font-size: 14px;
  line-height: 20px;

  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 18px;
  }
`;
