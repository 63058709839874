import React from 'react';
import styled from 'styled-components';

export const SvgContainer = styled.div`
  height: auto;
  width: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${props => (props.color ? `${props.color}` : 'currentColor')};

  svg {
    fill: ${props => (props.color ? `${props.color}` : 'currentColor')};
    height: ${props => (props.height ? `${props.height}px` : '100%')};
    width: ${props => (props.width ? `${props.width}px` : '100%')};
  }
`;

export function IconBase(props) {
  const { color = 'currentColor', size = 14, style = {} } = props;

  return (
    <SvgContainer
      width={size}
      height={size}
      color={color}
      style={{ ...style, color }}
    >
      {props.children}
    </SvgContainer>
  );
}
