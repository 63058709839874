import React from 'react';
import copyToClipboard from 'copy-to-clipboard';
import { AddToken } from 'icons/AddToken';
import { CopyIcon } from 'icons/Copy';
import { RedirectIcon } from 'icons/Redirect';
import styled from 'styled-components';

const etherscanLink = address => `https://etherscan.io/address/${address}`;

export const Token = ({ token, displayWalletImport }) => {
  const { logo, symbol, name, address, decimals } = token;

  function handleCopyAddress() {
    copyToClipboard(address);
  }

  function handleRedirect() {
    const url = etherscanLink(address);
    window.open(url, '_blank').focus();
  }

  async function handleAddToken() {
    if (typeof window === 'undefined' || !window.ethereum) {
      return;
    }
    try {
      const { ethereum } = window;
      await ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: { address, symbol, decimals, image: logo },
        },
      });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Row>
      <Icon src={logo} alt="logo" />
      <Text>{name}</Text>

      <Button onClick={handleCopyAddress}>
        <CopyIcon />
      </Button>

      <Button onClick={handleRedirect}>
        <RedirectIcon />
      </Button>

      {displayWalletImport && (
        <Button onClick={handleAddToken}>
          <AddToken style={{ paddingBottom: 2 }} />
        </Button>
      )}
    </Row>
  );
};

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
`;

const Text = styled.p`
  margin: 0 12px 0 4px;
  font-size: 12px;
  line-height: 18px;
  color: #60697C;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;

  & + & {
    margin-left: 12px;
  }

  svg {
    fill: #9FAAC4;
  }

  &:hover {
    svg {
      fill: #1A5AFF;
    }
  }
`;
