import React from 'react';
import { IconBase } from './IconBase';

export function AddToken(props) {
  return (
    <IconBase {...props}>
      <svg width="14" height="14" viewBox="0 0 14 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 4C7.41421 4 7.75 4.33579 7.75 4.75V6.25H9.25C9.66421 6.25 10 6.58579 10 7C10 7.41421 9.66421 7.75 9.25 7.75H7.75V9.25C7.75 9.66421 7.41421 10 7 10C6.58579 10 6.25 9.66421 6.25 9.25V7.75H4.75C4.33579 7.75 4 7.41421 4 7C4 6.58579 4.33579 6.25 4.75 6.25H6.25V4.75C6.25 4.33579 6.58579 4 7 4Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M0.75 7C0.75 3.54822 3.54822 0.75 7 0.75C10.4518 0.75 13.25 3.54822 13.25 7C13.25 10.4518 10.4518 13.25 7 13.25C3.54822 13.25 0.75 10.4518 0.75 7ZM7 2.25C4.37665 2.25 2.25 4.37665 2.25 7C2.25 9.62335 4.37665 11.75 7 11.75C9.62335 11.75 11.75 9.62335 11.75 7C11.75 4.37665 9.62335 2.25 7 2.25Z" />
      </svg>
    </IconBase>
  );
}
