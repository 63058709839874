import React from 'react';

import {
  NavLink,
  NavLinkInternal,
  NavLinkItem
} from '../style';

export const HeaderLink = ({ label, link, isExternal, handleClick, isSubLink = false }) => (
  <NavLinkItem isSubLink={isSubLink}>
    {isExternal ? (
      <NavLink
        href={link}
        target="_blank"
        rel="noreferrer noopener"
        onClick={handleClick}
      >
        {label}
      </NavLink>
    ) : (
      <NavLinkInternal
        to={link}
        onClick={handleClick}
      >
        {label}
      </NavLinkInternal>
    )}
  </NavLinkItem>
);
