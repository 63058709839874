import React from 'react';
import styled from 'styled-components';

export const LogoBox = ({ className, delay, logo, title }) => (
  <div data-aos="fade-up" data-aos-delay={delay} >
    <View className={className}>
      <Text>{title}</Text>
      {logo.map(({ logo, name }, idx) => (
        <ImageWrapper key={idx}>
          <img src={logo} alt={name} />
        </ImageWrapper>
      ))}
    </View>
  </div>
);

const View = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  background: #fff;

  @media (max-width: 767px) {
    padding: 24px;
    box-shadow: 0 24px 60px rgba(2, 10, 33, 0.04);
  }
`;

const Text = styled.p`
  font-weight: 600;
  font-size: 28px;
  line-height: 40px;
  color: #0B0E14;

  @media (max-width: 1024px) {
    width: 100%;
    text-align: center;
    margin-bottom: 32px;
  }

  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 32px;
    margin: 0;
  }
`;

const ImageWrapper = styled.div`
  @media (max-width: 767px) {
    display: flex;
    justify-content: center;
    width: 50%;
    height: 25px;
    margin-top: 24px;

    img {
      max-height: 100%;
      max-width: 113px;
    }
  }
`;
