import React from 'react';

export function MailIcon({ color }) {
  color = color || '#9FAAC4';
  return (
    <svg fill="none" viewBox="0 0 512 512" height="16" width="16" xmlns="http://www.w3.org/2000/svg">
        <path d="M424 80H88a56.06 56.06 0 00-56 56v240a56.06 56.06 0 0056 56h336a56.06 56.06 0 0056-56V136a56.06 56.06 0 00-56-56zm-14.18 92.63l-144 112a16 16 0 01-19.64 0l-144-112a16 16 0 1119.64-25.26L256 251.73l134.18-104.36a16 16 0 0119.64 25.26z" fill={color} />
    </svg>
  );
}
