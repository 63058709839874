import React from 'react';

import { DropdownItem, HeaderLink, MobileExpandableItem } from './components';
import { HEADER_DROPDOWNS, HEADER_LINKS, GOVERNANCE_LINKS } from './constants';
import {
  EarnButton,
  EarnButtonWrapper,
  NavBar
} from './style';

export const Navigation = ({ isMobile, setDisplayMobileMenu }) => {
  const headerLinks = HEADER_LINKS.map((linkProps, key) => (
    <HeaderLink key={key} {...linkProps} handleClick={() => setDisplayMobileMenu(false)} />
  ));

  const headerDropdowns = HEADER_DROPDOWNS.map((link, index) => (
    <DropdownItem {...link} key={index} handleClick={() => setDisplayMobileMenu(false)} />
  ));

  return (
    <>
      <NavBar>
        {headerLinks}
        {isMobile ? (
          <MobileExpandableItem
            label="Governance"
            links={GOVERNANCE_LINKS}
            handleClick={() => setDisplayMobileMenu(false)}
          />
        ) : headerDropdowns}
      </NavBar>

      <EarnButtonWrapper>
        <EarnButton
          href="https://app.truefi.io"
          target="_blank"
          rel="noreferrer noopener"
        >
          Launch app
        </EarnButton>
      </EarnButtonWrapper>
    </>
  );
};
