import styled from 'styled-components';

const SectionTitle = styled.h2`
  font-weight: 600;
  font-size: 52px;
  line-height: 66px;

  @media (max-width: 900px) {
    font-size: 40px;
    line-height: 120%;
  }

  @media (max-width: 600px) {
    font-size: 24px;
  }
`;

export const SectionTitleDark = styled(SectionTitle)`
  color: #0B0E14;
`;

export const SectionTitleLight = styled(SectionTitle)`
  color: #FFFFFF;
`;
