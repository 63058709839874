import React from 'react';

import {
  DropdownContainer,
  DropdownLabel,
  DropdownLink,
  DropdownList,
  DropdownLinkIcon,
  DropdownLinkText
} from '../style';

export const DropdownItem = ({ links, label, handleClick }) => (
  <DropdownContainer>
    <DropdownLabel>{label}</DropdownLabel>
    <DropdownList>
      {links.map(({ icon, link, label }, index) => {
        const Icon = icon;
        return (
          <DropdownLink
            href={link}
            target="_blank"
            rel="noreferrer noopener"
            onClick={handleClick}
            key={index}
          >
            {icon && (
              <DropdownLinkIcon>
                <Icon color="currentColor" />
              </DropdownLinkIcon>
            )}
            <DropdownLinkText>
              {label}
            </DropdownLinkText>
          </DropdownLink>
        );
      })}
    </DropdownList>
  </DropdownContainer>
);
