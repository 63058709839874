import React from 'react';
import { IconBase } from './IconBase';

export function CopyIcon(props) {
  return (
    <IconBase {...props}>
      <svg width="14" height="14" viewBox="0 0 14 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.5 0.75C1.5335 0.75 0.75 1.5335 0.75 2.5V7.5C0.75 8.4665 1.5335 9.25 2.5 9.25H3.25C3.66421 9.25 4 8.91421 4 8.5C4 8.08579 3.66421 7.75 3.25 7.75H2.5C2.36193 7.75 2.25 7.63807 2.25 7.5V2.5C2.25 2.36193 2.36193 2.25 2.5 2.25H7.5C7.63807 2.25 7.75 2.36193 7.75 2.5V3.25C7.75 3.66421 8.08579 4 8.5 4C8.91421 4 9.25 3.66421 9.25 3.25V2.5C9.25 1.5335 8.4665 0.75 7.5 0.75H2.5Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M6.5 4.75C5.5335 4.75 4.75 5.5335 4.75 6.5V11.5C4.75 12.4665 5.5335 13.25 6.5 13.25H11.5C12.4665 13.25 13.25 12.4665 13.25 11.5V6.5C13.25 5.5335 12.4665 4.75 11.5 4.75H6.5ZM6.25 6.5C6.25 6.36193 6.36193 6.25 6.5 6.25H11.5C11.6381 6.25 11.75 6.36193 11.75 6.5V11.5C11.75 11.6381 11.6381 11.75 11.5 11.75H6.5C6.36193 11.75 6.25 11.6381 6.25 11.5V6.5Z" />
      </svg>
    </IconBase>
  );
}
