export const ChainId = {
  Mainnet: 1,
  Ropsten: 3,
  Ganache: 1337,
};

export const SUPPORTED_CHAINS = [ChainId.Mainnet, ChainId.Ropsten, ChainId.Ganache];

export const NETWORK_CONNECTIONS = {
  [ChainId.Mainnet]: 'https://mainnet.infura.io/v3/f88abc181a4a45a6bc47bdda05a94944',
  [ChainId.Ropsten]: 'https://ropsten.infura.io/v3/f88abc181a4a45a6bc47bdda05a94944',
  [ChainId.Ganache]: 'http://127.0.0.1:8545',
};

export const NETWORK_NAMES = {
  [ChainId.Mainnet]: 'mainnet',
  [ChainId.Ropsten]: 'ropsten',
  [ChainId.Ganache]: 'ganache',
};

export const DEFAULT_CHAIN = ChainId.Mainnet;
