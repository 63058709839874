import React, { useEffect, useRef, useState } from 'react';

import truefiLogo from '../../icons/truefilogo.svg';
import mobileMenuBtn from '../../icons/MenuIconOpen.svg';
import mobileMenuClose from '../../icons/MenuIconClose.svg';
import { useResponsiveHelpers } from '../../hooks/useResponsiveHelpers';

import {
  HeaderWrapper,
  Container,
  HeaderRow,
  LogoLink,
  MobileMenuButton,
  MobileMenuWrapper,
  NavWrapper
} from './style';
import { Navigation } from './Navigation';

function Header() {
  const headerElem = useRef();

  const { windowWidth } = useResponsiveHelpers();
  const [displayMobileMenu, setDisplayMobileMenu] = useState(false);
  const [menuOffset, setMenuOffset] = useState(undefined);
  const isMobileWidth = windowWidth && windowWidth < 768;

  useEffect(() => {
    const isExpandedDesktop = windowWidth > 1130;
    // displaying full screen mobile menu => lock page scroll
    if (displayMobileMenu && isMobileWidth) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    // transitioning to desktop => reset mobile menu
    if (isExpandedDesktop && displayMobileMenu) {
      setDisplayMobileMenu(false);
    }
  }, [displayMobileMenu, windowWidth, isMobileWidth]);

  const toggleMobileMenu = () => {
    const willOpen = !displayMobileMenu;
    if (willOpen && isMobileWidth) {
      setMenuOffset(getMenuOffset());
    } else {
      setMenuOffset(undefined);
    }
    setDisplayMobileMenu(willOpen);
  };

  const getMenuOffset = () => {
    const defaultOffset = 70;
    if (!headerElem.current) {
      return defaultOffset;
    }
    const { height, top } = headerElem.current.getBoundingClientRect();
    return height + top;
  };

  return (
    <HeaderWrapper ref={headerElem}>
      <Container>
        <HeaderRow>
          <LogoLink to="/">
            <img src={truefiLogo} alt="true-fi logo" />
          </LogoLink>

          <NavWrapper>
            <Navigation isMobile={isMobileWidth} setDisplayMobileMenu={setDisplayMobileMenu}/>
          </NavWrapper>

          <MobileMenuButton onClick={toggleMobileMenu}>
            <img
              alt="menu button"
              src={displayMobileMenu ? mobileMenuClose : mobileMenuBtn}
            />
          </MobileMenuButton>
        </HeaderRow>

        <MobileMenuWrapper isDisplayed={displayMobileMenu} menuOffset={menuOffset}>
          {displayMobileMenu && <Navigation isMobile={isMobileWidth} setDisplayMobileMenu={setDisplayMobileMenu}/>}
        </MobileMenuWrapper>
      </Container>
    </HeaderWrapper>
  );
}

export { Header };
