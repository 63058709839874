import React from 'react';

export function TelegramIcon({ color, width, height }) {
  color = color || '#9FAAC4';
  height = height || '14px';
  width = width || '18px';

  return (
    <svg width={width} height={height} viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17 0.3C17 0.2 16.9 0.1 16.8 0C16.6 0 16.4 0 16.2 0C16.2 0 2.2 5 1.4 5.6C1.1 5.8 1.1 5.9 0.999997 5.9C0.899997 6.3 1.3 6.5 1.3 6.5L4.9 7.7C5 7.7 5 7.7 5.1 7.7C5.9 7.2 13.4 2.5 13.8 2.3C13.9 2.3 13.9 2.3 13.9 2.3C13.7 2.9 7.3 8.6 7.3 8.7C7.3 8.7 7.2 8.8 7.3 8.8L7 12.3C7 12.3 6.9 13.4 8 12.3C8.8 11.5 9.5 10.9 9.9 10.6C11.1 11.5 12.5 12.4 13.1 12.9C13.3 13.1 13.6 13.2 13.8 13.2C14.1 13.2 14.4 12.9 14.5 12.6C14.5 12.6 17.1 2.3 17.1 0.9C17.1 0.8 17.1 0.7 17.1 0.6C17 0.5 17 0.4 17 0.3Z" fill={color} />
    </svg>
  );
}
