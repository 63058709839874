import React from 'react';
import styled from 'styled-components';
import { SectionBright } from 'components/layout/SectionBright';
import { Container } from 'components/layout/Container';
import bloombergLogo from 'images/logotypes/bloomberg.svg';
import blockworksLogo from 'images/logotypes/blockworks.svg';
import techcrunchLogo from 'images/logotypes/techcrunch.svg';
import coindeskLogo from 'images/logotypes/coindesk.svg';
import wsjLogo from 'images/logotypes/wsj.svg';

export const CtaSectionWrapper = ({ children }) => (
  <View>
    <Container>
      <Row>
        {children}
      </Row>
      <Footer>
        <Label data-aos="fade-up">Featured in...</Label>
        <List>
          <Item data-aos="fade-up" data-aos-delay="250">
            <img src={wsjLogo} alt="wallstreetjournal" style={{ maxHeight: '36px' }} />
          </Item>
          <Item data-aos="fade-up" data-aos-delay="300">
            <img src={bloombergLogo} alt="bloomberg" />
          </Item>
          <Item data-aos="fade-up" data-aos-delay="350">
            <img src={techcrunchLogo} alt="techcrunch" />
          </Item>
          <Item data-aos="fade-up" data-aos-delay="400">
            <img src={blockworksLogo} alt="blockworks" />
          </Item>
          <Item data-aos="fade-up" data-aos-delay="450">
            <img src={coindeskLogo} alt="coindesk" />
          </Item>
        </List>
      </Footer>
    </Container>
  </View>
);

const View = styled(SectionBright)`
  padding: 104px 0 56px;

  @media (max-width: 900px) {
    padding: 64px 0;
  }

  @media (max-width: 767px) {
    padding: 40px 0 16px;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 92px;

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 92px;
  }

  @media (max-width: 767px) {
    margin-bottom: 24px;
  }
`;

const Label = styled.p`
  font-weight: 600;
  font-size: 28px;
  line-height: 40px;
  color: #F8F9FB;

  @media (max-width: 1200px) {
    margin-bottom: 24px;
  }

  @media (max-width: 600px) {
    font-size: 18px;
    line-height: 120%;
  }
`;

const List = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 1200px) {
    justify-content: space-between;
    width: 100%;
  }

  @media (max-width: 767px) {
    flex-wrap: wrap;
  }
`;

const Item = styled.div`
  margin-left: 60px;

  img {
    max-width: 100%;
    min-width: 112px;
  }

  @media (max-width: 1200px) {
    margin: 0;

    & + & {
      margin-left: 43px;
    }
  }

  @media (max-width: 767px) {
    && {
      width: 45%;
      margin: 0 0 24px;
    }

    img {
      min-width: initial;
    }
  }
`;

const Footer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
